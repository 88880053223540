import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import React, { useState } from 'react';
import SYN from './assets/SYN.png';

const theme = createTheme({
  palette: {
    purple: {
      main: '#5B4776',
      contrastText: '#FFFFFF'
    }
  }
});

function App() {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    npi: '',
    email: '',
    time: ''
  });
  const [isEventTimeError, setIsEventTimeError] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.time === '') {
      setIsEventTimeError(true);
      return;
    }
    fetch('/api/registrants/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });
    handleOpen();
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRegisterHereScroll = () => {
    document.querySelector('.cta-register').scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <div className="upper-container">
          <img
            alt="Register for Webcast"
            src="https://nurtec-registration.s3.amazonaws.com/Header.png"
            className="header-img"
          />
          <div className="content">
            <div className="indication-container">
              <div className="indication">
                <div className="isi-header">INDICATIONS</div>
                <div className="isi-section">
                  <div>Nurtec ODT is indicated in adults for the:</div>
                  <div>&bull; acute treatment of migraine with or without aura</div>
                  <div>&bull; preventive treatment of episodic migraine</div>
                </div>
              </div>
              <div className="indication-disclaimer"><sup>*</sup>Per IQVIA as oral brand in<br />class (oral CGRP receptor<br />antagonists): number one<br />prescribed and number<br />one in new prescriptions<br />since 8/6/21. Data current<br />as of 11/22.</div>
            </div>
            <div className="green-text title" onClick={handleRegisterHereScroll} style={{ cursor: 'pointer' }}>
              REGISTER HERE TO JOIN US FOR THIS WEBCAST EVENT!
            </div>
            <div className="purple-text subtitle program-container">
              <div className="program-date">
                June 28, 2023 at 6:00 <span className="subscript">PM</span> ET/PT
              </div>
              <div className="program-desc">Recognize Early. Diagnose Promptly. Treat and Prevent Migraine Attacks With a Prescription Option.</div>
            </div>
            <div className="green-text isi-header">IMPORTANT SAFETY INFORMATION</div>
            <div className="isi-section">
              <span className="isi-subheader">Contraindications:</span> Hypersensitivity to Nurtec ODT
              or any of its components.
            </div>
            <div className="isi-section">
              <span className="isi-subheader">Warnings and Precautions:</span> If a serious
              hypersensitivity reaction occurs, discontinue Nurtec ODT and initiate appropriate
              therapy. Serious hypersensitivity reactions have included dyspnea and rash and can occur
              days after administration.
            </div>
            <div className="isi-section">
              <span className="isi-subheader">Adverse Reactions:</span> The most common adverse
              reactions were nausea (2.7% in patients who received Nurtec ODT compared to 0.8% in
              patients who received placebo) and abdominal pain/dyspepsia (2.4% in patients who
              received Nurtec ODT compared to 0.8% in patients who received placebo).
              Hypersensitivity, including dyspnea and rash, occurred in less than 1% of patients
              treated with Nurtec ODT.
            </div>
            <div className="isi-section">
              <span className="isi-subheader">Drug Interactions:</span> Avoid concomitant
              administration of Nurtec ODT with strong inhibitors of CYP3A4, or strong or moderate
              inducers of CPY3A. Avoid another dose of Nurtec ODT within 48 hours when it is
              administered with moderate inhibitors of CYP3A4 or potent inhibitors of P-gp.
            </div>
            <div className="isi-section">
              <span className="isi-subheader">Use in Specific Populations:</span>{' '}
              <span style={{ fontStyle: 'italic' }}>Pregnancy</span>: It is not known if Nurtec ODT
              can harm an unborn baby. <span style={{ fontStyle: 'italic' }}>Lactation</span>: The
              transfer of rimegepant into breastmilk is low (&#60;1%).{' '}
              <span style={{ fontStyle: 'italic' }}>Hepatic impairment</span>: Avoid use of Nurtec ODT
              in persons with severe hepatic impairment.{' '}
              <span style={{ fontStyle: 'italic' }}>Renal impairment</span>: Avoid use in patients
              with end-stage renal disease.
            </div>
            <br />
            <div className="isi-section isi-subheader isi-pi">
              Please click here for full{' '}
              <a href="https://labeling.pfizer.com/ShowLabeling.aspx?%7B%7Bmy.link%7D%7D%7B%7Bmy.Account_Token%7D%7D%7B%7Blead.PfizerCustomerID%7D%7D&PRM=ACC&LKA=LA34&id=19036&LNK=GL_PH4-C2">
                Prescribing Information
              </a>
              .
            </div>
            <div className="cta-container">
              <div className="cta-register">REGISTER NOW</div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-item">
                <div className="form-label">First Name*</div>
                <TextField
                  required
                  type="text"
                  name="first_name"
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-item">
                <div className="form-label">Last Name*</div>
                <TextField
                  required
                  type="text"
                  name="last_name"
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-item">
                <div className="form-label">NPI (optional)</div>
                <TextField type="text" name="npi" fullWidth onChange={handleInputChange} />
              </div>
              <div className="form-item">
                <div className="form-label">Email*</div>
                <TextField
                  required
                  type="email"
                  name="email"
                  fullWidth
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-item">
                <div className="form-label">Choose Event Time*</div>
                <div className="event-time-btn-container">
                  <Button
                    className={`event-time-btn ${formData.time === '6:00 PM ET' ? 'pressed' : ''}`}
                    type="button"
                    variant="contained"
                    disableElevation
                    onClick={() => handleInputChange({
                      target: {
                        name: 'time',
                        value: '6:00 PM ET'
                      }
                    })}
                  >
                    <div>6:00 <span className="subscript">PM</span> EST</div>
                  </Button>
                  <Button
                    className={`event-time-btn ${formData.time === '6:00 PM PT' ? 'pressed' : ''}`}
                    type="button"
                    variant="contained"
                    disableElevation
                    onClick={() => handleInputChange({
                      target: {
                        name: 'time',
                        value: '6:00 PM PT'
                      }
                    })}
                  >
                    <div>6:00 <span className="subscript">PM</span> PST</div>
                  </Button>
                </div>
                <div className={`event-time-err ${isEventTimeError ? 'show' : ''}`}>
                  Please choose an event time.
                </div>
              </div>
              <div className="required-fields"><sup>*</sup>Please complete all fields to finalize your registration for this event.</div>
              <div className="register-btn-container">
                <Button
                  className="register-btn"
                  type="submit"
                  color="purple"
                  variant="contained"
                  text="Register"
                  id="Register_Button_Submission"
                  disableElevation
                >
                  REGISTER
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="content">
          <div className="grey-text bottom-footer">
            <div className="isi-section no-margin">
              This advertisement is intended only for healthcare professionals in the United States.
            </div>
            <br />
            <br />
            <br />
            <div>
              <img
                className="footer-img"
                src="https://nurtec-registration.s3.amazonaws.com/pfizer-logo.png"
                alt="Prizer"
              />
            </div>
            <div className="footer">
              <div style={{ flex: 1 }}>
                &copy; 2023 Pfizer Inc. All rights reserved. NURTEC and the NURTEC Logo are trademarks of Pfizer Ireland Pharmaceuticals.
                <div className="footer-date">May 2023. PP-NNT-USA-0500.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            backgroundColor: '#FFFFFF',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            padding: 10
          }}
        >
          <div
            style={{
              backgroundColor: '#F1F1F1',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <img alt="success" src={SYN} style={{ width: 80, marginTop: 20 }} />
            <div
              style={{
                textAlign: 'center',
                fontWeight: 700,
                fontSize: 18,
                color: '#878B89',
                marginTop: 20,
                marginBottom: 20
              }}
            >
              Thank you for registering.
            </div>
            <div
              style={{
                textAlign: 'center',
                fontWeight: 600,
                fontSize: 16,
                color: '#AAAAAA',
                marginBottom: 20
              }}
            >
              See you at the event!
            </div>
          </div>
        </div>
      </Modal>
    </ThemeProvider>
  );
}

export default App;
